/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h2: "h2",
    div: "div",
    code: "code",
    pre: "pre"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "The Signatu Consent system is built on a REST API.\nMake sure you are familiar with the ", React.createElement(_components.a, {
    href: "../architecture/consent"
  }, "Architecture"), "."), "\n", React.createElement(_components.h2, {
    id: "using-the-rest-api",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#using-the-rest-api",
    "aria-label": "using the rest api permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Using the REST API"), "\n", React.createElement(_components.p, null, "You can use the REST API directly from any environment that can do HTTP ", React.createElement(_components.code, null, "POST"), " requests.\nRequests are authorized using a ", React.createElement(_components.a, {
    href: "/docs/api/v0/oauth"
  }, "OAuth Bearer Token"), ". Refer to the full\n", React.createElement(_components.a, {
    href: "/docs/api/v0/consent/apidoc"
  }, "API documentation"), " for details."), "\n", React.createElement(_components.h2, {
    id: "crm-integration",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#crm-integration",
    "aria-label": "crm integration permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "CRM integration"), "\n", React.createElement(_components.p, null, "Signatu can send ", React.createElement(_components.a, {
    href: "/docs/api/v0/integration/events"
  }, "consent events"), " to other systems using the flexible\n", React.createElement(_components.a, {
    href: "/docs/api/v0/integration/channels"
  }, "Channels"), " system."), "\n", React.createElement(_components.h2, {
    id: "opt-out-support",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#opt-out-support",
    "aria-label": "opt out support permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Opt-out support"), "\n", React.createElement(_components.p, null, "If you don’t use Consent as legal basis you can still use the Signatu Consent system. If you use e.g., legitimate interest,\nyou are still required to provide opt-out for your users. In practice everything is the same, except that no user action\nis interpreted as permission to process in the legitimate interest case."), "\n", React.createElement(_components.p, null, "We provide the ", React.createElement(_components.code, null, "optOut"), " flag for all the ", React.createElement(_components.a, {
    href: "/docs/api/v0/sdk/react/consent"
  }, "Consent components in the SDK"), ", e.g.,"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<ConsentCheckbox consentTarget={{ target: 'signatu.com/demo/optout' }} optOut={true}>\n    We use legitimate interest, and this checkbox will be checked by default even when the user has not taken any\n    action.\n</ConsentCheckbox>\n")), "\n", React.createElement(_components.p, null, "Note that the consent status texts are automatically turned off when ", React.createElement(_components.code, null, "optOut"), " is activated."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
